import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Field,
    Text,
    Textarea,
    TextareaOnChangeData,
    makeStyles,
    mergeClasses,
    tokens,
} from "@fluentui/react-components";
import { Dismiss24Regular, Send24Regular } from "@fluentui/react-icons";
import React, { useCallback, useEffect, useState } from "react";
import { AirFile } from "../../../../../domain/airFile/airFile";
import { User } from "../../../../../domain/user/user";
import { state } from "../../../../state/stateAdapter";
import { FileSelectionsStateValue } from "../../../../state/stateValues";
import { useCommonFluentuiStyles } from "../../../../styles/griffel";
import { useDirectoryObjects } from "../../../../../application/useCases/useDirectoryObjects";
import Loader from "../../../../components/Loader/Loader";
import { apiCall } from "../../../../../infrastructure/api/api";
import { useNotification } from "../../../../../application/useCases/useNotification";

type RequestAccessDialogProps = {
    isOpen: boolean;
    onAfterApply?: () => void;
    filesSelection: FileSelectionsStateValue<AirFile>;
    user: User;
    onClose: () => void;
};

const useStyles = makeStyles({
    message: {
        height: "80px",
    },
    inputs: { display: "grid", rowGap: "8px" },
    date: {
        display: "grid",
        rowGap: "11px",
    },
    dateTags: {
        display: "grid",
        gridAutoFlow: "column",
        gridAutoColumns: "max-content",
        columnGap: "4px",
    },
    actions: {
        display: "grid",
        gridAutoFlow: "column",
        gridAutoColumns: "max-content",
        columnGap: "8px",
    },
    emails: {
        display: "grid",
        gridAutoFlow: "row",
        rowGap: "4px",
        gridAutoRows: "max-content",
    },
    tagsList: {
        listStyleType: "none",
        marginBottom: tokens.spacingVerticalXXS,
        marginTop: 0,
        paddingLeft: 0,
        display: "flex",
        gridGap: tokens.spacingHorizontalXXS,
        flexWrap: "wrap",
    },
    addEmailsContainer: {
        display: "grid",
        rowGap: "8px",
    },
});

async function getUserEmail(name: string): Promise<string> {
    try {
        const res = await useDirectoryObjects.searchUser({
            query: name,
        });

        if (res === undefined || res === null || res.length === 0) {
            return "";
        }

        return res[0].email;
    } catch (error) {
        return "";
    }
}

function RequestAccessDialog(
    props: RequestAccessDialogProps,
): JSX.Element | null {
    const classes = useStyles();
    const commonClasses = useCommonFluentuiStyles();

    const [email, setEmail] = useState<null | string>(null);
    const [message, setMessage] = useState("");
    const [isGetEmailQueryInProgress, setIsGetEmailQueryInProgress] =
        useState(true);
    const [isSendRequestQueryInProgress, setIsSendRequestQueryInProgress] =
        useState(false);

    const onClose = useCallback(() => {
        state.appDialog.set(null);
        if (props.onClose) {
            props.onClose();
        }
    }, [props]);

    const onChangeMessage = useCallback(
        (_, data: TextareaOnChangeData): void => {
            setMessage(data.value);
        },
        [setMessage],
    );

    const sendReqguest = async (): Promise<void> => {
        setIsSendRequestQueryInProgress(true);
        try {
            const ids = Array.from(props.filesSelection.values()).map(
                (file: AirFile) => file.id,
            );

            const res = await apiCall("/api/asset-access-request/send", {
                method: "POST",
                body: JSON.stringify({
                    ids,
                    text: message,
                }),
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            });

            if (!res.ok) {
                throw new Error("Request failed");
            }
            props.onClose();
            useNotification.show("Request sent", "success");
        } catch (error) {
            useNotification.show("Error while sending request", "error");
        }

        setIsSendRequestQueryInProgress(false);
    };

    useEffect(() => {
        getUserEmail(props.user.name).then(e => {
            setEmail(e);
            setIsGetEmailQueryInProgress(false);
        });
    }, [props.user.name]);

    return (
        <Dialog
            onOpenChange={(_e, data): void => {
                if (!data.open) {
                    onClose();
                }
            }}
            open={props.isOpen || isSendRequestQueryInProgress}
        >
            <DialogSurface
                onClick={(e): void => {
                    e.stopPropagation();
                }}
            >
                <DialogBody>
                    <DialogTitle
                        action={
                            <DialogTrigger action="close">
                                <Button
                                    appearance="subtle"
                                    aria-label="close"
                                    icon={<Dismiss24Regular />}
                                />
                            </DialogTrigger>
                        }
                    >
                        Request Access
                    </DialogTitle>

                    <DialogContent>
                        <Text title={props.user.name}>
                            From {props.user.name} ({email})
                        </Text>

                        <br />

                        <div className="app-dialog__content">
                            <Accordion collapsible>
                                <AccordionItem value="1">
                                    <AccordionHeader>
                                        Selected items:{" "}
                                    </AccordionHeader>
                                    <AccordionPanel>
                                        <div className="dialog-details">
                                            {Array.from(
                                                props.filesSelection.values(),
                                            ).map(i => (
                                                <Text key={i.id}>
                                                    {i.title}
                                                </Text>
                                            ))}
                                        </div>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>

                            <Field label="Message">
                                <Textarea
                                    resize="vertical"
                                    placeholder="Type the optional message"
                                    onChange={onChangeMessage}
                                />
                            </Field>
                        </div>
                    </DialogContent>

                    <DialogActions>
                        <div className={classes.actions}>
                            <Button appearance="secondary" onClick={onClose}>
                                Cancel
                            </Button>

                            <Button
                                icon={<Send24Regular />}
                                appearance="primary"
                                onClick={sendReqguest}
                            >
                                Send
                            </Button>
                        </div>
                    </DialogActions>

                    {/* Loader inside dialog */}
                    {isGetEmailQueryInProgress ||
                    isSendRequestQueryInProgress ? (
                        <div
                            className={mergeClasses(
                                commonClasses.neutralBg,
                                "sharing-link-dialog__loader-container",
                            )}
                        >
                            <Loader text="Processing..." />
                        </div>
                    ) : null}
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}

export function openSharingLinkDialog(params: RequestAccessDialogProps): void {
    state.appDialog.set(() => <RequestAccessDialog {...params} />);
}

export default RequestAccessDialog;
